import React, { useState } from 'react';
import { FormControl, Grid, TextField, Card, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, IconButton, Checkbox, Alert, ListItemText, Button, Typography } from '@mui/material';
import { CodeList, ConditionDef, ItemDef, ItemGroupDef } from '../../../interface/SdmInterfaces';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Props {
    conditionalDef: ConditionDef[]; // Replace with your specific type
    setConditionalDef: React.Dispatch<React.SetStateAction<ConditionDef[]>>; // Replace with your specific type
    itemRef: any;
    itemDefs: ItemDef[];
    codeLists: CodeList[];
    itemGroupOID: any;
    questionOID: any;
    itemGroupDefs: any;
    setItemGroupDefs: React.Dispatch<React.SetStateAction<ItemGroupDef[]>>;
    setConditionOid: any;
}

const ConditionalDef: React.FC<Props> = ({ conditionalDef, setConditionalDef, itemRef, itemDefs, codeLists, itemGroupOID, questionOID, itemGroupDefs, setItemGroupDefs, setConditionOid }) => {
    const uniqueOIDs = new Set(); // Create a Set to track unique OIDs

    //operand  changes 

    const operandOptions = [
        { value: '==', label: '==' },
        { value: '!=', label: '!=' },
        { value: '<=', label: '<=' },
        { value: '>=', label: '>=' },
    ];


    const [conditional, setConditional] = useState<any>(''); // To handle conditional ref
    const [conditionalCodeList, setConditionalCodeList] = useState<CodeList[]>([]);
    const [codedVale, setCodedValue] = useState<any>('' || []); // State to hold selected operand
    const [dataType, setDataType] = useState<any>(); // To handle conditional ref
    const [operand, setOperand] = useState<string>(''); // State to hold selected operand
    const [multiSelect, setMultiSelect] = useState<any>([]); // State to hold selected operand
    const [conditionals, setConditionals] = useState<string[]>([]);
    const [operands, setOperands] = useState<string[]>([]);
    const [codedValues, setCodedValues] = useState<string[]>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    // State to hold the list of conditions
    const [conditions, setConditions] = useState([
        { conditional: '', operand: '', codedVale: '', dataType: 'text' }
    ]);

    // Handlers
    const handleAddCondition = (conditionOid) => {
        // Find the selected condition
        const selectedCondition = conditionalDef.find(condition => condition.OID === conditionOid);

        // Check if any existing FormalExpression contains an empty string
        const hasEmptyCondition = selectedCondition && selectedCondition.FormalExpression.text.includes('"" "" ""');

        if (hasEmptyCondition) {
            // Set alert message and show the alert
            setAlertMessage("Cannot add new condition: an empty condition already exists.");
            setShowAlert(true);
            return; // Stop execution if validation fails
        }

        // Proceed with updating the condition
        const updatedConditionalDef = conditionalDef.map(condition => {
            if (condition.OID === conditionOid) {
                const newCondition = `("" "" "")`;

                // Return the updated condition with new FormalExpression
                return {
                    ...condition,
                    FormalExpression: {
                        ...condition.FormalExpression,
                        text: condition.FormalExpression?.text
                            ? `${condition.FormalExpression.text} || ${newCondition}`
                            : newCondition,
                        Context: condition.FormalExpression?.Context || "" // Ensure Context is set
                    }
                };
            }
            // Return the original condition for non-matching items
            return condition;
        });

        // Update the state with the modified conditionalDef array
        setConditionalDef(updatedConditionalDef);

        // Optional: Log the updated array or the specific item
        // //console.log(updatedConditionalDef.find(res => res.OID === conditionOid));
    };
    React.useEffect(() => {
        setConditionalCodeList(codeLists)
        //console.log(codedVale, operand, conditional)

    }, [conditionalCodeList]);
    //handleQuestions selecting for conditional Rendering
    const handleConditionalItemChange = (itemGroupOID: string, e: React.ChangeEvent<{ value: unknown }>, index: number) => {
        const questionItemDef = itemDefs?.filter(q => q.OID === e.target.value);
        let newDataType, updatedCodeLists,findCodeListIndex;

        questionItemDef.map(itemData => {
            newDataType = itemData.DataType;
            updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
            findCodeListIndex=codeLists.findIndex(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
        });

        // setConditional(newConditional); // Update the selected question (conditional)
        setDataType(newDataType);
        const newConditionals = [...conditionals];
        newConditionals[index] = e.target.value as string;
        setConditionals(newConditionals);
        if (updatedCodeLists.length > 0) {
            // Create a new array and update only the specific index
            setConditionalCodeList(prevCodeLists => {
                const newCodeLists = [...prevCodeLists];
                newCodeLists[index] = updatedCodeLists[0]; // Assuming only one updated code list needs to be applied
                return newCodeLists;
            });
        }
        // if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
        createConditionalDefFormalExpression(itemGroupOID, questionOID, newConditionals, operands, codedValues, index);

        // }
    };

    const handleDefaultItemChange = (selectedValue: any) => {
        //////console.log(selectedValue);

        const itemDef = itemDefs.find(item => item.OID === selectedValue);
        if (itemDef) {
            setConditional(itemDef.OID);

            //////console.log('Conditional:', itemDef.Question.TranslatedText.text);
        }

        const questionItemDef = itemDefs.filter(q => q.OID === selectedValue);
        questionItemDef.forEach(itemData => {
            //////console.log('DataType:', itemData.DataType, 'CodeListRef:', itemData.CodeListRef);
            setDataType(itemData.DataType);

            const updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
            if (updatedCodeLists.length > 0) {
                //////console.log('Updated Code List:', updatedCodeLists);
                setConditionalCodeList(updatedCodeLists);
            }
        });
    };


    React.useEffect(() => {
        setConditionalCodeList(codeLists)
        handleConditionExpression();
        ////console.log(codedVale, operand, conditional)

    }, [itemRef.CollectionExceptionConditionOID]);// Empty dependency array ensures it runs only once on component mount

    // Function to handle condition expression
    const handleConditionExpression = () => {
        const conditionsToProcess = conditionalDef.filter(condition =>
            condition.OID === itemRef.CollectionExceptionConditionOID
        );

        const newDataTypes: string[] = [];
        const newCodedValues: string[] = [];
        const newOperands: string[] = [];
        const newConditionals: string[] = [];

        conditionsToProcess.forEach(conditionDef => {
            const FormalExpression = conditionDef?.FormalExpression.text;
            const matches = FormalExpression?.match(/([^\s()]+)\s*([!<>=]+)\s*"([^"]+)"/);

            if (matches) {
                const itemFilteredDef = itemDefs.find(id => id.OID === matches[1]);

                if (itemFilteredDef) {
                    newDataTypes.push(itemFilteredDef.DataType);

                    const codeList = codeLists.find(cl => cl.OID === itemFilteredDef.CodeListRef?.CodeListOID);

                    if (itemFilteredDef.CodeListRef) {
                        if (itemFilteredDef.DataType === 'multiselect' || itemFilteredDef.DataType === 'multiSelectComboBox') {
                            const selectedValues = matches[3] ? matches[3].split(',') : [];
                            newCodedValues.push(...selectedValues); // Spread operator to add multiple values
                            newOperands.push(matches[2]);
                            newConditionals.push(itemFilteredDef.OID || '');
                            handleDefaultItemChange(itemFilteredDef.OID);
                        } else {
                            const codeListItem = codeList?.CodeListItem.find(codedValue => codedValue.CodedValue === matches[3]);
                            newCodedValues.push(codeListItem?.CodedValue || '');
                            newOperands.push(matches[2]);
                            newConditionals.push(itemFilteredDef.OID || '');
                            handleDefaultItemChange(itemFilteredDef.OID);
                        }
                    } else {
                        newCodedValues.push(matches[3] || '');
                        newOperands.push(matches[2]);
                        newConditionals.push(itemFilteredDef.OID || '');
                    }
                }
            }
        });

        // Update states with the collected values
        setDataType(newDataTypes);
        setCodedValues(newCodedValues);
        setOperands(newOperands);
        setConditionals(newConditionals);
    };

    // createConditionalDefFormalExpression
    const createConditionalDefFormalExpression =
        (itemGroupConditionOid,
            itemConditionOid,
            conditionalCodeList,
            operands,
            codedValues,
            index) => {
            // Check if ItemGroupOID already exists in itemGroupDefs
            const existingItemGroupIndex = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupConditionOid);

            if (existingItemGroupIndex !== -1) {
                // If ItemGroupOID exists, find the ItemRef
                const existingItemGroup = { ...itemGroupDefs[existingItemGroupIndex] };
                const existingItemRef = existingItemGroup.ItemRef.find(itemRef => itemRef.ItemOID === questionOID);
                // //console.log(existingItemGroup,existingItemRef?.CollectionExceptionConditionOID,conditionalCodeList,operands,codedValues)
                //console.log(operands[index])
                if (existingItemRef?.CollectionExceptionConditionOID) {
                    const existingConditionDefIndex = conditionalDef.findIndex(condition => condition.OID === existingItemRef.CollectionExceptionConditionOID);
                    if (existingConditionDefIndex !== -1) {
                        const updatedConditionDef = { ...conditionalDef[existingConditionDefIndex] };

                        // Split existing conditions
                        const conditions = updatedConditionDef.FormalExpression.text
                            .split(/\|\|/)
                            .map(c => c.trim());


                        // Ensure values are not empty
                        const safeConditionalCodeList = conditionalCodeList[index] || ''; // Get the value at the index
                        const safeOperand = operands[index] || ''; // Get the value at the index
                        const safeCodedVale = codedValues[index] || ''; // Get the value at the index
                        // //console.log(safeConditionalCodeList,safeOperand,safeCodedVale);

                        // Update the specific condition at the given index
                        if (index < conditions.length) {
                            conditions[index] = `!(${safeConditionalCodeList} ${safeOperand} "${safeCodedVale}")`;
                        } else {
                            // Handle the case where the index might be out of bounds (optional)
                            console.warn(`Index ${index} is out of bounds for conditions array.`);
                        }

                        // //console.log(conditions[index], safeConditionalCodeList, safeOperand, safeCodedVale);

                        // Rebuild the FormalExpression
                        updatedConditionDef.FormalExpression = {
                            Context: "OpenEDC",
                            text: conditions.join(' || ') // Join conditions back into a single string
                        };

                        // Update the conditionalDef array
                        const updatedConditionalDefArray = [
                            ...conditionalDef.slice(0, existingConditionDefIndex),
                            updatedConditionDef,
                            ...conditionalDef.slice(existingConditionDefIndex + 1)
                        ];

                        // Set the updated conditionalDef array
                        setConditionalDef(updatedConditionalDefArray);
                        setConditionOid(prev => {
                            const newSet = new Set(prev);
                            newSet.add(existingItemRef?.CollectionExceptionConditionOID);
                            // //console.log('newSet', newSet)
                            return newSet;
                        });
                    }

                }
            }
        };

    // for creating ConditionalDefFormalExpression
    const handleItemValue = (event: any, itemGroupConditionOid, itemOid, index) => {
        // //console.log(event.target.value)
        const newCodedValues = [...codedValues];
        newCodedValues[index] = event.target.value as string;
        setCodedValues(newCodedValues);

        // const itemDef = itemDefs.find(itemData => itemData.OID === itemOid || itemData.DataType === 'multiSelectComboBox');
        // setCodedValue(event.target.value);
        if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
            createConditionalDefFormalExpression(itemGroupConditionOid, itemOid, conditionals, operands, newCodedValues, index);

        }

    }

    //to set the operand and perform create ConditionalDefFormalExpression
    const handleOperandChange = (event: any, itemGroupConditionOid, itemOid, index) => {
        const newOperands = [...operands];
        newOperands[index] = event.target.value as string;
        setOperands(newOperands);
        //console.log(operands, event)

        setOperand(event.target.value as string); // Update selected operand
        if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
            createConditionalDefFormalExpression(itemGroupConditionOid, itemOid, conditionals, newOperands, codedValues, index);

        }

    };

    const addNewCondition = () => {
        // Implement your logic to add the new condition
        const newCondition = {
            itemGroupOID,
            questionOID: conditional,
            operand,
            codedVale
        };

        // Logic to find existing condition and update its formal expression
        const updatedConditionalDefArray = conditionalDef.map(cond => {
            if (cond.OID === newCondition.itemGroupOID) {
                const newFormalExpression = `!(${conditionalCodeList} ${operand} "${codedVale}")`;
                return {
                    ...cond,
                    FormalExpression: {
                        Context: "OpenEDC",
                        text: cond.FormalExpression.text ? `${cond.FormalExpression.text} || ${newFormalExpression}` : newFormalExpression
                    }
                };
            }
            return cond;
        });

        setConditionalDef(updatedConditionalDefArray);
    };

    // Function to delete a condition
    const handleDeleteCondition = (conditionOID: string, itemGroupOid: string) => {
        // Find the index of the condition to delete
        // //console.log(conditionOID, itemGroupOid)
        const index = conditionalDef.findIndex(cd => cd.OID === conditionOID);
        if (index === -1) {
            return; // Condition not found, handle accordingly
        }
        // //console.log(index)

        // Remove condition from conditionalDef state
        const updatedConditionalDef = [...conditionalDef];
        const deletedConditionalDef = updatedConditionalDef.splice(index, 1)[0];
        setConditionalDef(updatedConditionalDef);

        // Update itemGroupDefs to set CollectionExceptionConditionOID to null for the specified ItemGroupDef
        const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
            if (itemGroup.OID === itemGroupOid) {
                return {
                    ...itemGroup,
                    ItemRef: itemGroup.ItemRef.map((itemRef) => {
                        if (itemRef.CollectionExceptionConditionOID === deletedConditionalDef.OID) {

                            // Use destructuring to create a new object without the CollectionExceptionConditionOID property
                            const { CollectionExceptionConditionOID, ...rest } = itemRef;
                            return rest;
                        }
                        else if (itemRef.CollectionExceptionConditionOID === conditionOID) {
                            const { CollectionExceptionConditionOID, ...rest } = itemRef;
                            return rest;
                        }
                        return itemRef;
                    }),
                };
            }
            return itemGroup;
        });
        // Update state with the modified itemGroupDefs
        setItemGroupDefs(updatedItemGroupDefs);
        // //console.log(itemGroupDefs)
    };

    // Filter out items with OID === questionOID
    const filterAndRemoveItemDefs = (itemDefs: ItemDef[], questionOID: string) => {
        const seenOIDs = new Set<string>();

        // Filter by removing duplicate OIDs
        const filteredItemDefs = itemDefs.filter(item => {
            // If OID has not been seen yet, add it to the set and include it in the filtered result
            if (!seenOIDs.has(item.OID)) {
                seenOIDs.add(item.OID);
                return true;
            }
            // If OID is already in the set, skip this item
            return false;
        });

        // Remove the item with the specific questionOID from the filteredItemDefs
        const finalFilteredItemDefs = filteredItemDefs.filter(item => item.OID !== questionOID);

        // //console.log(finalFilteredItemDefs);
        return finalFilteredItemDefs;
    };

    // Example usage
    const filteredItemDefs = filterAndRemoveItemDefs(itemDefs, questionOID);

    const filteredConditionalDef = conditionalDef
  .filter(res => res.OID === itemRef.CollectionExceptionConditionOID) // First filter based on condition
  .filter(res => {
    // Check if the OID has already been encountered
    if (!uniqueOIDs.has(res.OID)) {
      uniqueOIDs.add(res.OID); // Add the OID to the Set if it hasn't been added before
      return true; // Keep the item
    }
    return false; // Filter out duplicates
  });
    return (

        <>
            {showAlert && (
                <Alert className="mt-2" severity="warning" onClose={() => setShowAlert(false)}>
                    {alertMessage}
                </Alert>
            )}
            <Card className="questionBuilderCard conditionalBorder">
                <Grid container alignItems="center">
                    <Grid item xs={11}>
                        {/* Conditional Rendering */}
                        Conditional Rendering
                    </Grid>
                    <Grid item xs={1} mt={0} display="flex" justifyContent="flex-end">
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteCondition(itemRef.CollectionExceptionConditionOID, itemGroupOID)}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {/* <Typography>
                    {conditionalDef.filter((re: any) => re.OID).map((re: any) => re.FormalExpression.text).join(", ")}
                </Typography>           */}
                <>
                    {filteredConditionalDef
                        .filter(res => res.OID === itemRef.CollectionExceptionConditionOID) // Filter based on the condition
                        .map(res => {
                            const FormalExpression = res.FormalExpression.text || '';
                         //console.log(FormalExpression, res)
                            
                            const conditions = FormalExpression.split(/\|\|/).map(condition => condition.trim());
                          console.log(conditions)
                            const regex = /(I\.[\d]+)\s*(==|!=|>|<)\s*["']?([\d]*)["']?|(["']?)$/;
                            return       conditions.map((condition, index) => {
                                const match = condition.match(regex);
                              //console.log(match);
                                if (match) {
                                  const [fullMatch, leftOperand, operator, rightValue] = match;
                                //console.log(`Left Operand: ${leftOperand}, Operator: ${operator},  Right Value: ${rightValue}`);

                                // Retrieve the current values from state or fallback to the extracted values
                                const currentConditional = conditionals[index] || leftOperand;
                                //console.log(ItemDef?.CodeListRef?.CodeListOID)
                                const currentOperand = operands[index] || operator;
                                const currentCodedValue = codedValues[index] !== undefined ? codedValues[index] : rightValue;
                         // Get the associated CodeList
                                const currentCodedValueSafe = currentCodedValue !== undefined ? currentCodedValue : ''; // Handle undefined
                                return (
                                    <Grid container spacing={2} key={index}>
                                        {/* Question Select */}
                                        <Grid item xs={12} md={5} mt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Question</InputLabel>
                                                <Select
                                                    labelId={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}
                                                    id={`demo-select-small-${itemRef.CollectionExceptionConditionOID}`}
                                                    value={currentConditional || undefined}
                                                    size="small"
                                                    label="Question"
                                                    onChange={(e: any) => handleConditionalItemChange(itemGroupOID, e, index)}  // Pass index here
                                                    fullWidth
                                                >
                                                    {filteredItemDefs.map(option => (
                                                        <MenuItem key={option.OID} value={option.OID}>
                                                            {option.Question?.TranslatedText?.text}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value={undefined}>None</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Operand Select */}
                                        <Grid item xs={12} md={2} mt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`operand-select-label-${itemRef.CollectionExceptionConditionOID}`}>Operand</InputLabel>
                                                <Select
                                                    labelId={`operand-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                    id={`operand-select-${itemRef.CollectionExceptionConditionOID}`}
                                                    value={currentOperand || undefined}
                                                    label="Operand"
                                                    onChange={(e: any) => handleOperandChange(e, itemGroupOID, questionOID, index)}
                                                    fullWidth
                                                    size='small'
                                                >
                                                    {operandOptions.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value={undefined}>None</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Coded Value Input */}
                                        <Grid item xs={12} md={5} mt={1}>
                                            {(() => {
                                                const selectedItem = filteredItemDefs.find(item => item.OID === currentConditional);
                                                const currentCodedValueSafe = currentCodedValue !== undefined ? currentCodedValue : ''; // Handle undefined
                                                //console.log(currentCodedValueSafe, "currentCodedValueSafe")
                                           
                                                const ItemDef = itemDefs.find(i => i.OID === currentConditional);
                                                // Logic to update the conditionalCodeList
                                                let codeListData: CodeList | undefined;
                                                const codeListIndex = codeLists.findIndex(res => res.OID === currentConditional);
                                                if (ItemDef?.DataType === 'multiselect' || ItemDef?.DataType === 'singleselect') {
                                                    codeListData = codeLists.find(res => res.OID === ItemDef?.CodeListRef?.CodeListOID);
                                                  //console.log(conditionalCodeList[codeListIndex], codeListData);
                                                }
                                                
                                                if (!selectedItem) return null;

                                                const { DataType } = selectedItem;

                                                switch (DataType) {
                                                    case 'boolean':
                                                        return (
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                                                <Select
                                                                    labelId={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                                    id={`value-select-${itemRef.CollectionExceptionConditionOID}`}
                                                                    value={currentCodedValueSafe }
                                                                    label="Value"
                                                                    onChange={(event: any) => handleItemValue(event, itemGroupOID, questionOID, index)}
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                                    <MenuItem value="No">No</MenuItem>
                                                                    <MenuItem value={undefined}>None</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        );

                                                    case 'text':
                                                    case 'multilinetext':
                                                        return (
                                                            <TextField
                                                                label="Text Field"
                                                                fullWidth
                                                                size="small"
                                                                value={currentCodedValueSafe}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, questionOID, index)}
                                                            />
                                                        );

                                                    case 'integer':
                                                        return (
                                                            <TextField
                                                                id="integer-input"
                                                                label="Integer Input"
                                                                fullWidth
                                                                size="small"
                                                                value={currentCodedValueSafe || undefined}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, questionOID, index)}
                                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                            />
                                                        );

                                                    case 'datetime':
                                                        return (
                                                            <TextField
                                                                id="datetime-input"
                                                                label="Date Time"
                                                                fullWidth
                                                                size="small"
                                                                type="datetime-local"
                                                                value={currentCodedValueSafe || undefined}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, questionOID, index)}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        );

                                                    case 'date':
                                                        return (
                                                            <TextField
                                                                id="date-input"
                                                                label="Date"
                                                                fullWidth
                                                                size="small"
                                                                type="date"
                                                                value={currentCodedValueSafe || undefined}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, questionOID, index)}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        );

                                                    case 'multiSelectComboBox':
                                                    case 'multiselect':
                                                        return (
                                                            <FormControl fullWidth size="small">
                                                            <InputLabel id={`multi-select-label-${itemGroupOID}`}>Value</InputLabel>
                                                            <Select
                                                                labelId={`multi-select-label-${itemGroupOID}`}
                                                                id={`operand-select-${itemGroupOID}`}
                                                                multiple
                                                                label="Value"
                                                                value={Array.isArray(currentCodedValueSafe) ? currentCodedValueSafe : []} // Ensure value is an array
                                                                onChange={(e) => handleItemValue(e, itemGroupOID, questionOID, index)}
                                                                renderValue={(selected) =>
                                                                  Array.isArray(selected) ? selected.join(', ') : ''
                                                                } // Ensure selected is an array
                                                            >
                                                                {codeListData?.CodeListItem.map((codeListItem) => (
                                                                    <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                                        <Checkbox
                                                                            checked={currentCodedValueSafe.indexOf(codeListItem.CodedValue) > -1} // Check if the value is selected
                                                                        />
                                                                        <ListItemText primary={codeListItem.Decode?.TranslatedText?.text || codeListItem.CodedValue} /> {/* Fallback to CodedValue if no text */}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem value={undefined}>None</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        
                                                        );

                                                    default:
                                                        return (
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                                                <Select
                                                                    labelId={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                                    id={`value-select-${itemRef.CollectionExceptionConditionOID}`}
                                                                    value={currentCodedValueSafe || ''} // Ensure value is correctly bound
                                                                    label="Value"
                                                                    onChange={(e: any) => handleItemValue(e, itemGroupOID, questionOID, index)}
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    {codeListData?.CodeListItem.map(codeListItem => (
                                                                        <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                                            {codeListItem.Decode?.TranslatedText?.text || codeListItem.CodedValue} {/* Display the text or fallback to CodedValue */}
                                                                        </MenuItem>
                                                                    ))}
                                                                    <MenuItem value={undefined}>None</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        );
                                                }
                                            })()}
                                        </Grid>
                                    </Grid>
                                );
                            }})
                        })}
                    <Grid item xs={12} md={12}>
                        <Button onClick={() => handleAddCondition(itemRef.CollectionExceptionConditionOID)}>Add condition</Button>
                    </Grid>
                </>

            </Card>


        </>
    );
};

export default ConditionalDef;
